// Libraries
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Button = forwardRef(
  ({ children, className, primary, outlined, text, disabled, onClick, type }, ref) => (
    <button
      className={classNames(
        'button',
        {
          'is-primary is-primary-background': primary,
          'is-outlined is-primary-outline is-secondary-color': outlined,
          'is-text is-secondary-color': text,
        },
        className
      )}
      // Disabling rule because of it does not allow for dynamic
      // type. https://github.com/yannickcr/eslint-plugin-react/issues/1555
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </button>
  )
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  primary: PropTypes.bool,
  outlined: PropTypes.bool,
  text: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  primary: false,
  outlined: false,
  text: false,
  disabled: false,
  type: 'button',
  onClick: () => {},
};

export default Button;
